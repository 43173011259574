<template>
  <div class="my-10" v-if="pageLoaded">
    <div class="flex items-center justify-between mb-2">
      <p class="text-lg font-extrabold">Talents</p>
      <router-link to="/talentpool" class="font-bold cursor-pointer text-brand-black">View More</router-link>
    </div>
    <div v-if="talents?.length > 0" class="flex gap-x-4">
      <div class="border border-gray-400 rounded p-3 w-60" v-for="talent in talents.slice(0, 5)" :key="talent.uuid">
        <div class="flex items-center mb-3">
          <img :src="talent.avatar" class="w-10 h-10 rounded-md pr-2" />
          <p class="font-cooper  text-sm">{{ talent.name }}</p>
        </div>
        <div class="flex items-center">
          <!-- <Location class="w-2 h-4" /> -->
          <!-- <p>Location: </p> -->
          <p class="capitaliz ml- 2">{{ getCountry(talent.country)?.value }},{{ talent.state }}</p>
        </div>
        <div class="flex items-center">
          <!-- <p>Role: </p> -->
          <p class="">{{ talent.professional_title_name }}</p>
        </div>
        <div class="flex items-center">
          <!-- <p>Exp: </p> -->
          <p class="">{{ talent.years_of_experience }} {{ talent.years_of_experience == 1 ? ' year' : ' years' }} experience</p>
        </div>
      </div>
    </div>
    <div v-else class="border border-gray-400 rounded p-3 w-60">
      <loading :is-loading="true" />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import TamButton from '@/components/TamButton.vue';
import Location from '@/assets/icons/location.svg?inline';
import { Cross } from '@/icons';
import { useStore } from 'vuex';
import Loading from './Loading.vue';

const arrayOfColours = ref(['text-green-400 border-green-200', 'text-yellow-400 border-yellow-200', 'text-blue-400 border-blue-200']);
const numberOfIterations = ref(6);
const store = useStore();
const pageLoaded = ref(false);

const talents = computed(() => {
  return store.getters['global/getAllTalents'];
});

const getCountry = (value) => {
  return store.getters['global/getCountries'].find((y) => y.key === value);
};

const getContractClasses = () => {
  return Math.floor(Math.random() * numberOfIterations.value) % arrayOfColours.value.length;
};

onMounted(async () => {
  await store.dispatch('global/getAllTalents');
  pageLoaded.value = true;
});
</script>
