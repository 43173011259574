<template>
  <main v-if="pageLoaded">
    <div v-if="jobs.length < 1" class="mt-2">
      <Empty
        button
        @doSomething="openJob"
        :title="'There are no jobs posted.'"
        :text="'Your next best candidate is waiting for you. We have thousands of applicants looking for a new journey. Post a job now and make use of our all-in-one applicant tracking system'"
      >
        <Cross class="mr-2" fill="white" />
        <router-link to="/new-job"> <p>Post a job</p></router-link>
      </Empty>
      <!-- <router-link to="/new-job"
        ><tam-button class="px-4" @click="showJob" type="submit" color="infoOpacity" label="Post a job" :icon="Cross"
      /></router-link> -->
    </div>
    <div v-else class="">
      <div class="flex items-center justify-between my-4">
        <!-- <p class="font-extrabold text-lg">Job Update</p> -->
        <router-link to="/new-job"
          ><tam-button class="px-4" type="submit" color="primary" outline label="Post a job" :icon="Cross"
        /></router-link>
        <router-link to="/jobs" class="font-bold cursor-pointer text-brand-black">View More</router-link>
      </div>
      <div class="flex gap-x-4">
        <div class="border border-gray-400 rounded p-4 w-72 mb-6" v-for="job in jobs.slice(0, 5)" :key="job.uuid">
          <div class="flex justify-between items-center">
            <p
              class=" rounded-full px-1.5"
              :class="job.status == 'open' ? 'text-black bg-brand-green/50' : 'text-black bg-brand-danger/50'"
            >
              {{ job.status }}
            </p>
            <p class="rounded-full  px-2 border border-brand-primary">{{ job.work_type.split('_').join(' ') }}</p>
          </div>

          <p class=" font-bold text-base text-black font-cooper my-6">{{ job.job_title_name }}</p>

          <div class="flex flex-wrap gap-y-3.5 gap-x-2 mb-4">
            <p
              v-for="skill in job.skills"
              :key="skill"
              class="border rounded-full border-brand-original px-2 py-1 "
              :class="[arrayOfColours[getContractClasses()]]"
            >
              {{ skill }}
            </p>
          </div>
          <p>{{ job.applications_count }} Applied</p>
        </div>
      </div>
    </div>
  </main>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import TamButton from '@/components/TamButton.vue';
import { Cross } from '@/icons';
import Empty from '../components/Empty.vue';
import { useStore } from 'vuex';

const store = useStore();
const arrayOfColours = ref(['text-green-400 border-green-200', 'text-[#481e3d] border-#481e3d/20', 'text-blue-400 border-blue-200']);
const numberOfIterations = ref(6);
const pageLoaded = ref(false);

const jobs = computed(() => {
  return store.getters['global/getAllJobs'];
});

const getContractClasses = () => {
  return Math.floor(Math.random() * numberOfIterations.value) % arrayOfColours.value.length;
  // return {
  //   'text-brand-primary': contract,
  //   'border-brand-black': contract,
  //   // style3: true, // This one is always going to be on and will have a bunch of shared styles between all of them
  // };
};

onMounted(async () => {
  await store.dispatch('global/getAllJobs');
  pageLoaded.value = true;
});
</script>
